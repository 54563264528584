<div class="home-container">
    <mat-card class="home-card">
        <div class="content-centered">
            <img class="brand-img" src="../../../assets/brand.png">
        </div>

        <br/>

        <div *ngIf="showSpinner" class="content-centered">
            <mat-spinner></mat-spinner>
        </div>

        <div *ngIf="success" class="success-text">
            <span>Success!</span>
            <br/>
        </div>

        <div *ngIf="error" class="error-text">
            <span>Error: {{ error }}</span>
            <br/>
        </div>

        <div *ngIf="!showSpinner" class="content-centered">
            <div>
                <mat-form-field appearance="fill" required>
                    <mat-label>GitHub Username</mat-label>
                    <input matInput [(ngModel)]="username" />
                </mat-form-field>

                <br/>

                <mat-form-field appearance="fill" required>
                    <mat-label>Reward Amount (USD)</mat-label>
                    <input matInput [(ngModel)]="amount" />
                </mat-form-field>

                <br/>

                <mat-form-field appearance="fill" required>
                    <mat-label>Reason</mat-label>
                    <input matInput [(ngModel)]="reason" />
                </mat-form-field>

                <br/>

                <button mat-flat-button class="reward-button" (click)="generateReward();">Reward User</button>
            </div>
        </div>
    </mat-card>
</div>
