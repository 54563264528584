import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var confetti: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    constructor(private http: HttpClient) { }

    showSpinner: boolean = true;
    username: string = '';
    rewards: Reward[] = [];

    doGitHubLogin(): void {
        let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');

        this.http.get('/api/login', {headers: headers}).subscribe((resp: any) => {
            window.location.href = resp.url;
        })
    }

    getRewards(): void {
        this.http.get('/api/rewards').subscribe((resp: any) => {
            if (resp.rewards !== null) {
                this.rewards = resp.rewards;
            } else {
                this.rewards = [];
            }

            this.username = resp.username;
            this.showSpinner = false;

            if (this.rewards.length > 0) {
                confetti.start(2000, 300, 500);
            }
        }, () => {
            this.showSpinner = false;
        });
    }

    ngOnInit(): void {
        this.getRewards();
    }
}

export interface Reward {
    code: string;
    owner: string;
    value: string;
    reason: string;
}

