import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
    constructor(private http: HttpClient,
                private router: Router) { }

    showSpinner: boolean = false;
    lockButton: boolean = false;
    success: boolean = false;
    error: string = '';

    username = '';
    amount = '';
    reason = '';

    getAdmin(): void {
        this.http.get('/api/admin').subscribe((resp: any) => {
            if (!resp.admin) {
                this.router.navigateByUrl('/');
            } else {
                this.showSpinner = false;
            };
        }, () => {
            this.router.navigateByUrl('/');
        });
    }

    generateReward(): void {
        this.error = '';
        this.success = false;
        this.lockButton = true;

        const body = {
            username: this.username,
            value: this.amount,
            reason: this.reason
        };

        this.http.post('/api/admin/generate', body).subscribe((resp: any) => {
            if (resp.success) {
                this.success = true;
                this.lockButton = false;
                setTimeout(() => {
                    this.success = false;
                }, 5000);
            } else {
                this.error = resp.error;
                this.lockButton = false;
            }
        });
    }

    ngOnInit() {
        this.getAdmin();
    }
}
