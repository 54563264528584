<div class="home-container">
    <mat-card class="home-card">
        <div class="content-centered">
            <img class="brand-img" src="../../../assets/brand.png">
        </div>

        <br/>
       
        <div *ngIf="showSpinner" class="content-centered">
            <mat-spinner></mat-spinner>
        </div>

        <div *ngIf="!showSpinner && !username" class="content-centered">
            <button mat-flat-button color="primary" class="login-button" (click)="doGitHubLogin();">
                <img class="login-icon" src="../../../assets/github-32.png" />
                <span>Login with GitHub</span>
            </button>
        </div>
        
        <div *ngIf="!showSpinner && username">
            <div class="content-centered">
                <p class="heartwarming-message-container">
                    Hi <b>{{ username }}</b>! Thank you for contributing to the Hak5 community! As a
                    token of our gratitude, the codes listed below have been awarded to you and
                    can be used in the Hak5 Shop.
                </p>
            </div>

            <br/>

            <div class="content-centered">
                <mat-card class="rewards-table-card mat-elevation-z6">
                    <mat-card-content>
                        <div class="content-centered" *ngIf="rewards.length === 0">
                            <span>Unfortunately, you don't currently have any rewards :(</span>
                        </div>

                        <table class="mat-table rewards-table" *ngIf="rewards.length > 0">
                            <tr class="mat-header-row">
                                <th class="mat-header-cell">Code</th>
                                <th class="mat-header-cell">Value</th>
                                <th class="mat-header-cell">Reason</th>
                            </tr>
                            <tr class="mat-row" *ngFor="let reward of rewards">
                                <td class="mat-cell">{{ reward.code }}</td>
                                <td class="mat-cell">${{ reward.value }}</td>
                                <td class="mat-cell">{{ reward.reason }}</td>
                            </tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-card>
</div>
